import apiHelper from 'services/apiHelper';
import { stringHelper } from './string.helper';

const createQuizAttempt = async ({ scanId, scanType, answerId }) => {
	const api_url = localStorage.getItem('API_URL');
	const options = apiHelper.composeOptionsForAnonymousPost({
		answerId,
		scanType,
	});
	const url = `${api_url}/api/v1.0/scans/${scanId}/quiz-attempts`;

	const response = await fetch(url, options);
	if (response.ok) {
		const result = await response.json();
		return result;
	}

	throw response.statusText || 'Api error';
};

const updateQuizAttempt = async (scanId, attemptId, attempt) => {
	const api_url = localStorage.getItem('API_URL');
	const options = apiHelper.composeOptionsForAnonymousPut({
		...attempt,
	});
	const url = `${api_url}/api/v1.0/scans/${scanId}/quiz-attempts/${attemptId}`;

	const response = await fetch(url, options);
	if (response.ok) {
		const result = await response.json();
		return result;
	}

	return response.text().then((text) => {
		throw new Error(stringHelper.normalizeQuotes(text));
	});
};

export default {
	createQuizAttempt,
	updateQuizAttempt,
};
