import { quizApi } from '../../../services';

export function useProductQuizForm(scanId, scanType) {
	async function handleSubmitAnswer(attempt) {
		try {
			if (scanId && scanType >= 0) {
				const result = await quizApi.createQuizAttempt({
					scanId: scanId,
					scanType: scanType,
					...attempt,
				});
				return result;
			}
		} catch (err) {}
	}
	async function handleSubmitWinnerInfo(attemptId, attempt) {
		try {
			if (attemptId && scanId) {
				const result = await quizApi.updateQuizAttempt(
					scanId,
					attemptId,
					attempt
				);
				return result;
			}
		} catch (err) {
			alert(err);
		}
	}

	return {
		handleSubmitAnswer,
		handleSubmitWinnerInfo,
	};
}
