import { experienceMapperHelper } from './experienceHelper';

function fillReplaceableFields({
	authenticationResult,
	authenticationData,
	usesTagTamper,
	tamperStatuses,
	serialNumber,
}) {
	var fields = {};
	if (authenticationResult) {
		fields['auth-result-text'] = authenticationData?.successful?.text || '';
		fields['auth-result-icon'] = authenticationData?.successful?.tag;
		fields['tamper-status-text'] =
			(usesTagTamper && tamperStatuses.filter((ts) => ts.active)[0]?.text) ||
			'';
		fields['tamper-status-icon'] =
			usesTagTamper && tamperStatuses.filter((ts) => ts.active)[0]?.tag;
		fields['serial-number'] = serialNumber;
	} else {
		fields['auth-result-text'] = authenticationData?.unsuccessful?.text || '';
		fields['auth-result-icon'] = authenticationData?.unsuccessful?.tag;
		fields['tamper-status-text'] = null;
		fields['tamper-status-icon'] = null;
		fields['serial-number'] = null;
	}

	return fields;
}

function replaceFields(htmlData, fields, mediaFiles) {
	var parser = new DOMParser();
	var doc = parser.parseFromString(htmlData, 'text/html');

	for (const [key, value] of Object.entries(fields)) {
		let replaceableNodes = doc?.querySelectorAll(`[data-replace=${key}]`);
		if (replaceableNodes && replaceableNodes.length > 0) {
			replaceableNodes.forEach((element) => {
				if (!value) {
					element.style.display = 'none';
				} else {
					if (element.tagName === 'IMG') {
						if (value) {
							element.src = `${experienceMapperHelper.getImageUrl(
								value,
								mediaFiles,
								null
							)}#TOKEN#`;
						}
					} else {
						element.innerHTML = value;
					}
				}
			});
		}
	}

	return doc.body.innerHTML;
}

function replaceQuizQuestion(htmlData, question) {
	var parser = new DOMParser();
	var doc = parser.parseFromString(htmlData, 'text/html');
	var titleTemplate = doc?.querySelectorAll(`[data-quiz-question-title]`)?.[0];
	var answerTemplateOriginal = doc?.querySelectorAll(`[data-quiz-answer]`)?.[0];

	if (!titleTemplate && !answerTemplateOriginal) return htmlData;

	if (titleTemplate && question) {
		titleTemplate.innerHTML = question.name;
	} else if (titleTemplate && !question) {
		titleTemplate.remove();
	}

	if (answerTemplateOriginal) {
		for (const answer of question?.answers ?? []) {
			const input = document.createElement('input');
			input.type = 'radio';
			input.className = 'question-option';
			input.value = answer.id;
			input.id = answer.id;
			input.name = 'answerId';
			input.setAttribute('data-quiz-answer', answer.id);
			input.style.display = 'none';
			answerTemplateOriginal.parentElement.appendChild(input);

			var newAnswer = answerTemplateOriginal.cloneNode();
			newAnswer.textContent = answer.name;
			newAnswer.setAttribute('for', answer.id);
			answerTemplateOriginal.parentElement.appendChild(newAnswer);
		}
		answerTemplateOriginal.remove();
	}

	return doc.body.innerHTML;
}

export const replacePageContentHelper = {
	fillReplaceableFields,
	replaceFields,
	replaceQuizQuestion,
};
