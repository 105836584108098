import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { useEventListener } from '../../../hooks';
import { background } from './product-custom-page.styles';
import ProductCustomPageContent from './product-custom-page-content';
import CookiePolicy from '../../cookie-policy/cookie-policy';
import ProductRatings from '../product-ratings/product-ratings';
import {
	useProductRatingsForm,
	useReplaceableRatings,
} from '../product-ratings';
import { useCustomAnalyticsForm } from '../product-ratings/useCustomAnalyticsForm';
import { useProductQuizForm } from '../product-quiz/useProductQuizForm';
import { formHelper, replacePageContentHelper } from '../../../services';
import ProductCustomShareableContent from './product-custom-shareable-content';

const ProductCustomPage = (props) => {
	const {
		content: originalContent,
		setViewByName,
		reference,
		contentPageReference,
		token,
		articles,
		scanId,
		scanType,
		ratingIcons,
		ratingCompletedResultType,
		getViewContent,
		quizQuestions,
		quizActive,
		quizClosedPageReference,
		quizWinnerPageReference,
		quizNoWinnerPageReference,
		quizWrongPageReference,
		quizCorrectPageReference,
	} = props;
	const [isCookiesModalOpen, setIsCookiesModalOpen] = useState(false);
	const [isRatingsModalOpen, setIsRatingsModalOpen] = useState(false);
	const [quizResult, setQuizResult] = useState();
	const [shareableContent, setShareableContent] = useState();
	const { t } = useTranslation(NAME_SPACE.SCAN_RATINGS);
	const {
		ratingsResult,
		errors: ratingErrors,
		ratingsData,
	} = useProductRatingsForm(scanId, articles, ratingCompletedResultType);
	const { handleSubmit: handleSubmitAnalytics } = useCustomAnalyticsForm(
		scanId,
		scanType
	);
	const { handleSubmitAnswer: handleSubmitQuizAnswer, handleSubmitWinnerInfo } =
		useProductQuizForm(scanId, scanType);
	const { replaceRatings } = useReplaceableRatings(articles, props.mediaFiles);

	const content = useMemo(() => {
		if (
			contentPageReference?.length > 0 &&
			originalContent?.includes('quiz-form')
		) {
			if (!quizActive) {
				const quizClosedContent = getViewContent(
					null,
					reference,
					quizClosedPageReference
				)?.props?.content;
				return quizClosedContent;
			} else {
				const question = quizQuestions?.filter(
					(q) => q.contentPageReference === contentPageReference
				)?.[0];
				return replacePageContentHelper.replaceQuizQuestion(
					originalContent,
					question
				);
			}
		}
		return originalContent;
	}, [originalContent, contentPageReference]);

	const handler = useCallback(
		async ({ target }) => {
			const linkElement = target?.closest('a');
			if (linkElement?.hasAttribute('data-cookies-button') || false) {
				setIsRatingsModalOpen(false);
				setIsCookiesModalOpen(true);
			} else if (
				linkElement?.hasAttribute('data-ratings-popup-button') ||
				false
			) {
				setIsCookiesModalOpen(false);
				setIsRatingsModalOpen(true);
			} else if (linkElement?.hasAttribute('data-target-share') || false) {
				let htmlData = null;
				const targetPage = linkElement.getAttribute('data-target-share');
				if (targetPage) {
					htmlData = getViewContent(null, 'Default Page', targetPage)?.props
						?.content;
				} else {
					htmlData = linkElement.parentElement?.querySelector(
						'main.share-container'
					)?.outerHTML;
				}
				if (htmlData) {
					setShareableContent(htmlData);
				}
			} else if (
				(target?.hasAttribute('data-quiz-answer') || false) &&
				target?.attributes['data-quiz-answer']?.value?.length > 0
			) {
				const form = target.closest('form');
				if (form) {
					form.style['touch-action'] = 'none';
					form.style['pointer-events'] = 'none';
					const formData = Object.fromEntries(new FormData(form));
					var result = await handleSubmitQuizAnswer({ ...formData });
					if (result?.isCorrect) {
						setQuizResult(result);
						setViewByName(null, reference, quizCorrectPageReference);
					} else {
						setViewByName(null, reference, quizWrongPageReference);
					}
					window.scrollTo(0, 0);
				}
			} else if (linkElement?.hasAttribute('data-target') || false) {
				let targetPage = linkElement.getAttribute('data-target');
				const linkType = linkElement.getAttribute('data-type');

				const form = target.closest('form');
				if (linkType === 'submit' && form) {
					const requireds = Array.from(form.querySelectorAll('[required]'));
					const isValid = requireds.every((required) => required.value);
					const isQuizWinnerForm = form['name'] === 'quiz-winner-form';
					if (isValid && (isQuizWinnerForm ? form.checkValidity() : true)) {
						const formData = formHelper.parseFormData(form);
						if (isQuizWinnerForm) {
							const winnerResult = await handleSubmitWinnerInfo(
								quizResult?.id,
								{ ...formData }
							);
							setQuizResult();
							if (winnerResult?.isWinner) {
								targetPage = quizWinnerPageReference;
							} else {
								targetPage = quizNoWinnerPageReference;
							}
						} else {
							handleSubmitAnalytics({ ...formData });
						}
						setViewByName(null, reference, targetPage);
						window.scrollTo(0, 0);
					}
				} else {
					if (linkType === 'next' && reference === 'AuthResult') {
						props.authenticationResult ? props.setNextView() : window.close();
					} else {
						setViewByName(null, reference, targetPage);
						window.scrollTo(0, 0);
					}
				}
			}
		},
		[reference, setViewByName, setIsCookiesModalOpen]
	);

	useEventListener('click', handler);

	const onShareRatings = useCallback(
		(ratingsTargetPage) => {
			let htmlData = null;
			let content = null;
			if (ratingsTargetPage) {
				htmlData = getViewContent(null, 'Default Page', ratingsTargetPage)
					?.props?.content;
			}
			if (htmlData) {
				content = replaceRatings(htmlData, ratingsData);
			} else {
				alert(t('share.error'));
			}

			if (content) {
				setShareableContent(content);
			} else {
				alert(t('share.noVoteToShare'));
			}
		},
		[ratingsResult, ratingsData]
	);

	function resetShareableContent() {
		setShareableContent('');
	}

	return (
		<div
			style={{
				...background,
			}}
		>
			<ProductCustomPageContent content={content} token={token} />
			<CookiePolicy
				isModalOpen={isCookiesModalOpen}
				fontColor={'white'}
				backgroundColor={'#000000bf'}
				onCloseModal={() => setIsCookiesModalOpen(false)}
			/>
			{isRatingsModalOpen && (
				<ProductRatings
					isModalOpen={isRatingsModalOpen}
					onCloseModal={() => setIsRatingsModalOpen(false)}
					onShareRatings={onShareRatings}
					isModal
					ratingIcons={ratingIcons}
					ratingsResult={ratingsResult}
					ratingErrors={ratingErrors}
					{...props}
				/>
			)}
			{shareableContent && (
				<ProductCustomShareableContent
					content={shareableContent}
					token={token}
					onFinishShare={resetShareableContent}
				/>
			)}
		</div>
	);
};

export default ProductCustomPage;
